import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Button, Form, Select} from 'antd';
import {SendOutlined} from '@ant-design/icons';
import {Cell, Grid, Row} from '../Grid';
import {getInteractionApi, liveFeedApiCall} from './api/press-api';
import { LiveFeedCard } from './LiveFeedCard';

export default function LiveFeed(props) {
	const {
		question,
		handleChangeQuestion,
		handleChange,
		onSubmit,
		approveQuestion,
		validation,
		form,
		approvedQuestionId,
		open,
		eventData,
		id,
		permanentRole,
		getApprovedQuestions,
		approvedQuestions,
		contentfulData,
	} = props;

	const {Option} = Select;
	const participants = eventData.participants;
	const options = participants && participants.toString().split(',');
	const [filteredQuestionData, setFilteredQuestion] = useState([]);
	const [allAskedQuestions, setAllAskedQuestions] = useState([]);
	const [orderedCombinedQuestions, setOrderedCombinedQuestions] = useState([])
	const [commentLengthError, setCommentLengthError] = useState(false)

	const fetchLiveFeedData = async (id) => {
		if (id) {
			const liveFeedApiCallRes = await liveFeedApiCall(id, permanentRole);
			if (liveFeedApiCallRes && liveFeedApiCallRes.data && liveFeedApiCallRes.data.length >= 0) {
				setFilteredQuestion(liveFeedApiCallRes.data);
			} else {
				setFilteredQuestion([]);
			}
		}
	};
	useEffect(() => {
		if (id) {
			fetchLiveFeedData(id);
		}
	}, [id, approveQuestion, approvedQuestionId, open]);


	const getInteractionData = async () => {
		const interactionApiRes = await getInteractionApi(id, 'PressOffice');

		if (interactionApiRes != undefined && interactionApiRes.length > 0) {
			setAllAskedQuestions(interactionApiRes);
		}
	}

	useEffect(() => {
		getApprovedQuestions();
		getInteractionData();
		const delayDebounceFn = setInterval(() => {
			getInteractionData();
			getApprovedQuestions();
		}, 5000);
		return () => clearInterval(delayDebounceFn);
	}, [id, approvedQuestionId, permanentRole]);

	useEffect(() => {
		if (permanentRole === 'Journalist') {
			const combinedQuestions = [...allAskedQuestions, ...approvedQuestions]
			const orderedQuestions = combinedQuestions.sort((a, b) => {
				const aDate = new Date(a.createdAt).getTime()
				const bDate = new Date(b.createdAt).getTime()
				if (aDate < bDate) return -1;
				if (aDate > bDate) return 1;
				return 0
			}).reverse()
			setOrderedCombinedQuestions(orderedQuestions);
		}
	}, [allAskedQuestions, approvedQuestions])

	const handleSubmit = () => {
		setCommentLengthError(false)
		if (question.length === 0) {
			setCommentLengthError(true)
		} else {
			onSubmit()
		}
	}

	const filteredQuestion =
		permanentRole === 'PressOffice' || permanentRole === 'Admin'
			? filteredQuestionData.length > 0 && filteredQuestionData.filter(element => element.status === '0').reverse()
			: orderedCombinedQuestions.length > 0 && orderedCombinedQuestions;

	return (
		<Grid className='press-live-feed__inner'>
			<Row>
				<Cell xlCols={12} lgCols={12} mdCols={6} smCols={2} className="livefeed__cell">
					<div className="journalist-questions">
						<div
							className={`${permanentRole === 'Journalist' ? 'journalist-questions__feed-journalist' : ''}`}>
							{filteredQuestion &&
								filteredQuestion.length > 0 &&
								filteredQuestion.map(info => (
									<LiveFeedCard key={info._id} info={info} contentfulData={contentfulData} permanentRole={permanentRole} approveQuestion={approveQuestion}/>

								))}
						</div>

						{permanentRole === 'Journalist' && (
							<div className="comment">
								<div className="question-content">
									<div className="journalist-select-question">
										<Form form={form}>
											<Form.Item name="Question">
												<Select className="select-question"
														placeholder={contentfulData.directQuestionTo} allowClear
														onChange={handleChange}>
													{options &&
														options.length > 0 &&
														options.map((element, index) => (
															<Option key={index} value={element}>
																{element}
															</Option>
														))}
												</Select>
											</Form.Item>

											<Button type="default" htmlType="submit" onClick={handleSubmit}>
												<SendOutlined/>
											</Button>
										</Form>
									</div>
									<textarea
										type="text"
										placeholder={contentfulData.commentPlaceholder}
										value={question}
										onChange={handleChangeQuestion}
										rows={2}
										maxLength={150}
										required
										className='question-content__textarea'
									/>
									{question && question.length && question.length >= 150 &&
										<p className="question-error">{validation}</p>}
									{commentLengthError && question.length < 1 &&
										<p className="question-error">Comment required</p>}

								</div>
							</div>
						)}
					</div>
				</Cell>
			</Row>
		</Grid>
	);
}
LiveFeed.propTypes = {
	contentfulData: PropTypes.object,
	question: PropTypes.string,
	handleChangeQuestion: PropTypes.func,
	handleChange: PropTypes.func,
	onSubmit: PropTypes.func,
	approveQuestion: PropTypes.func,
	showModal: PropTypes.func,
	validation: PropTypes.string,
	form: PropTypes.object,
	approvedQuestionId: PropTypes.string,
	open: PropTypes.bool,
	allQuestionList: PropTypes.array,
	eventData: PropTypes.object.isRequired,
	id: PropTypes.string,
	permanentRole: PropTypes.string,
	getApprovedQuestions: PropTypes.func,
	approvedQuestions: PropTypes.array
};
