import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Cell, Grid, Row } from '../Grid';
import ApprovedQuestion from './ApprovedQuestion';

export default function InteractionSection(props) {
	const {
		isSubmitQuestion,
		approvedQuestionId,
		askedQuestionId,
		askQuestion,
		contentfulData,
		permanentRole,
		getInteractionData,
		askedQuestionData,
		journalistAskedQuestions,
		getApprovedQuestions,
		approvedQuestions
	} = props

	const id = props.id ? props.id : null;

	const getPendingQuestions = () => {
		const pendingQuestions = askedQuestionData && askedQuestionData.length > 0 &&
			askedQuestionData.reduce((accumulator, currentValue) => {
				if(currentValue.status !== '2') {
					if(currentValue.status === '0') {
						currentValue.statusText = contentfulData.pending.toUpperCase();
						currentValue.className = 'pending'
					} else if (currentValue.status === '1') {
						currentValue.statusText = contentfulData.approved.toUpperCase()
						currentValue.className = 'approved'
					} else {
						currentValue.statusText = contentfulData.rejected.toUpperCase()
						currentValue.className = 'rejected'
					}
					accumulator.push(currentValue)
				}
				return accumulator
			}, [])
		return pendingQuestions
	}


	useEffect(() => {
		getInteractionData();
		const delayDebounceFn = setInterval(() => {
			getInteractionData();
		}, 5000);
		return () => clearInterval(delayDebounceFn);
	}, [id, askedQuestionId, isSubmitQuestion]);


	const relevantQuestions = permanentRole !== 'Journalist' ? askedQuestionData : journalistAskedQuestions;


	return (
		<Cell xlCols={12} lgCols={12} mdCols={3} smCols={2}>
			<div className="press-curator-screen-interactions">
				<div className="press-office-interactions">
					<Grid className='press-office-interactions__grid'>
						<Row>
							<Cell xlCols={6} lgCols={6} mdCols={6} smCols={2} className="section-one">
								<div className="press-questions clearfix">
									<h2 className="press-conf-question-header text-center pull-left">{contentfulData.question}</h2>
								</div>
								<ApprovedQuestion
									id={id}
									approvedQuestionId={approvedQuestionId}
									askedQuestionId={askedQuestionId}
									askQuestion={askQuestion}
									contentfulData={contentfulData}
									permanentRole={permanentRole}
									pendingQuestions={getPendingQuestions()}
									getApprovedQuestions={getApprovedQuestions}
									approvedQuestions={approvedQuestions}
								/>
							</Cell>
							<Cell xlCols={6} lgCols={6} mdCols={6} smCols={2} className="section-two">
								<div className="press-questions clearfix">
									<h2 className="press-conf-question-header text-center pull-left">{contentfulData.questionAsked}</h2>
								</div>
								<div className="press-conference-questions">
									{relevantQuestions.length > 0 &&
											[...relevantQuestions].reverse().map(info =><div key={info._id} className='press-conference-questions__asked'>
												<p className='press-conference-questions__asked-direct-to'>Question directed to: {info.participant}</p>
												<p >{info.question}</p>
											</div> )
									}
								</div>
							</Cell>
						</Row>
					</Grid>
				</div>
			</div>
		</Cell>
	);
}
InteractionSection.propTypes = {
	contentfulData: PropTypes.object,
	id: PropTypes.string,
	approvedQuestionId: PropTypes.string,
	askedQuestionId: PropTypes.string,
	askQuestion: PropTypes.func,
	question: PropTypes.string,
	ApprovedQuestion: PropTypes.func,
	permanentRole: PropTypes.string,
	isSubmitQuestion: PropTypes.bool,
	getInteractionData: PropTypes.func,
	askedQuestionData: PropTypes.array,
	journalistAskedQuestions: PropTypes.array,
	getApprovedQuestions: PropTypes.func,
	approvedQuestions: PropTypes.array
};
