import React, { useContext, useEffect, useState } from 'react';
import { ScreenLoadingOverlay } from '@sailgp/sailgp-shared-components';
import moment from 'moment';
import { Modal, Tooltip } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import pressconimg from '../../../../../public/images/pressconimg.png';
import { Cell } from '../../Grid';
import '../DevModule.scss';
import FormGroup from '../../FormGroup';
import UserContext from '../../../context/UserContext';
import { getEventsData } from './api/press-conference-api';
import TableComponent from '../UserRequestAdmin/UserRequestTable';
import eyeIcon from '../../../../../public/images/eye-icon.svg';
import './PressConferenceAdmin.scss'
import editIcon from '../../../../../public/images/Edit_fill.svg'
import { toast } from 'react-toastify';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { reFormatEventDate } from '../../../utils/formatDates';

export default function PressConferenceAdmin(props) {
	const {user,logOut} = useContext(UserContext)
	const [eventData, setEventData] = useState([]);
	const [pastEventsListData, setPastEventsListData] = useState([]);
	const [upcomingEventsListData, setUpcomingEventsListData] = useState([])
	const [baseUrl, setbaseUrl] = useState('');
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [isEditEventModalOpen, setEditEventModalOpen] = useState(false);
	const [editEventPrefillValues, setEditEventPrefillValues] = useState({});
	const [pastEventDataSource, setPastEventDataSource] = useState([])
	const [upcomingEventDataSource, setUpcomingEventDataSource] = useState([])
	const isAdminOrPressOffice =  user.userPermissionLevel <= 2;
	const {isLg, isXL} = useWindowDimensions()

	const getUsers = async () => {
		setLoading(false)
		const allEventsData = await getEventsData();
		setLoading(true)
		if(allEventsData.status == "logout"){
			logOut()
		}
		setEventData(allEventsData.data.reverse());
		setbaseUrl(allEventsData.baseUrl);
	};

	const showModal = () => {
		setIsModalOpen(true);
		setEditEventModalOpen(false);
	};


	const showEditEventModal =  (obj) => {
		const formattedEventDate = obj.eventDate && reFormatEventDate(obj.eventDate, obj.eventTimeZone)
		const formattedEventEndDate = obj.eventEndDate && reFormatEventDate(obj.eventEndDate, obj.eventEndTimeZone)
		const formattedLiveRace1Start = obj.liveRace1StartDate && reFormatEventDate(obj.liveRace1StartDate, obj.eventTimeZone)
		const formattedLiveRace1End = obj.liveRace1EndDate && reFormatEventDate(obj.liveRace1EndDate, obj.eventTimeZone)
		const formattedLiveRace2Start = obj.liveRace2StartDate && reFormatEventDate(obj.liveRace2StartDate, obj.eventTimeZone)
		const formattedLiveRace2End = obj.liveRace2EndDate && reFormatEventDate(obj.liveRace2EndDate, obj.eventTimeZone)
		const formattedObj = {...obj,
			eventDate: formattedEventDate,
			eventEndDate: formattedEventEndDate,
			liveRace1StartDate: formattedLiveRace1Start,
			liveRace1EndDate: formattedLiveRace1End,
			liveRace2StartDate: formattedLiveRace2Start,
			liveRace2EndDate: formattedLiveRace2End
		}
		setEditEventPrefillValues(formattedObj);
		setIsModalOpen(false);
		setEditEventModalOpen(true);
	};

	const handleOk = () => {
		setIsModalOpen(false);
		setEditEventModalOpen(false);
	};
	const handleCancel = () => {
		setIsModalOpen(false);
		setEditEventModalOpen(false);
	};

	const closeEditPopUp = async res => {
		setLoading(false)
		await setIsModalOpen(res);
		await setEditEventModalOpen(res);
		await getUsers();
		setLoading(true)
	};

	const tableFirstPosition = 'bottom';
	const tableSecondPosition = 'left';
	const pageSize = [10, 25, 50, 100];
	const tableLayout = 'fixed'

	useEffect(()=>{
		getUsers()
	}, [])

	const copyText = (eventId) => {
		navigator.clipboard.writeText(eventId)
		toast.success('ID copied!', {autoClose:1000, closeOnClick: true})
	}

	console.log(eventData, 'form data');
	useEffect(() => {
		const pastEventsTable = [];
		const upcomingEventsTable = [];
		const pastEventsList = [];
		const upcomingEventsList = []
		eventData.forEach((event, index) => {
			const isPastEvent = new Date(event.eventDate) < new Date()
			const formattedEventDate = reFormatEventDate(event.eventDate, event.eventTimeZone)

			const tableData =  {
				key: index,
				image: <div className={`c-press-conference-table__image-container`}>
					<img src={event.photoUrl ?  baseUrl.concat(event.photoUrl) : pressconimg} alt="event image" />
				</div>
				,
				title:  <p className={`c-press-conference-table__text`}>
					{event.eventTitle}
				</p>,
				date: <p className={`c-press-conference-table__text`}>
					{moment(formattedEventDate).format('DD MMM YYYY')}
				</p>,
				time: <p className={`c-press-conference-table__text`}>
					{event.eventTime}
				</p>,
				participants: <p className={`c-press-conference-table__text`}>
					{event.participants[0]}
				</p>,
				description: <p className={`c-press-conference-table__text`}>
					{event.eventDescription}
				</p>,
				id: <button className={`c-press-conference-table__copy-id`} onClick={() => copyText(event._id)}>
					<Tooltip title={event._id} color='#537577'>
						COPY ID
						<img src={eyeIcon} alt='info' className='c-press-conference-table__copy-id--icon'/>
					</Tooltip>
				</button>,
				edit: <button onClick={() => showEditEventModal(event)}>
					<img src={editIcon} alt={'edit'} className={`c-press-conference-table__edit`}/>
				</button>
			}
			if(isPastEvent) {
				pastEventsTable.push(tableData);
				pastEventsList.push(event)
			}
			else {
				upcomingEventsTable.push(tableData);
				upcomingEventsList.push(event)
			}
		})
		setUpcomingEventDataSource(upcomingEventsTable)
		setPastEventDataSource(pastEventsTable)
		setPastEventsListData(pastEventsList)
		setUpcomingEventsListData(upcomingEventsList)
	}, [eventData, baseUrl])

	const columns = [
		{
			id: 'image',
			key: 'image',
			title: 'Image',
			dataIndex: 'image'
		},
		{
			id: 'title',
			key: 'title',
			title: 'Title',
			dataIndex: 'title',
		},
		{
			id: 'date',
			key: 'date',
			title: 'Date',
			dataIndex: 'date',
		},
		{
			id: 'time',
			key: 'time',
			title: 'Time',
			dataIndex: 'time'
		},
		{
			id: 'participants',
			key: 'participants',
			title: 'Participants',
			dataIndex: 'participants',
		},
		{
			id: 'description',
			key: 'description',
			title: 'Description',
			dataIndex: 'description',
		},
		{
			id: 'id',
			key: 'id',
			title: 'ID',
			dataIndex: 'id',
		},
		{
			id: 'edit',
			key: 'edit',
			title: 'Edit',
			dataIndex: 'edit',
		}
	]
	const itemRender = (_, type, originalElement) => {
		if (type === 'prev') {
			return '<<';
		}
		if (type === 'next') {
			return '>>';
		}
		return originalElement;
	};

	const renderListItem = (event) => {
		return <section key={event._id} className={`c-press-conference-table__conference-container`}>
			<div className={`c-press-conference-table__info-container`}>
				<div className={`c-press-conference-table__title-container`}>
					<div className={`c-press-conference-table__image-container`}>
						<img className={`c-press-conference-table__image`} src={event.photoUrl ?  baseUrl.concat(event.photoUrl) : pressconimg} alt="event image" />
					</div>
					<p className={`c-press-conference-table__title`}>
						{event.eventTitle}
					</p>
				</div>
				<div className={`c-press-conference-table__date-container`}>
					<p>
						{moment(event.eventDate).format('DD MMM YYYY')}
					</p>
					<span className={`c-press-conference-table__dot-icon`}/>
					<p className={`c-press-conference-table__text`}>
						{`${event.eventTime} - ${event.eventEndTime}`}
					</p>
				</div>
				<p className={`c-press-conference-table__description`}>
					{event.eventDescription}
				</p>
			</div>
			<div className={`c-press-conference-table__button-container`}>
				<button className={`c-press-conference-table__copy-id`} onClick={() => copyText(event._id)}>
					<Tooltip title={event._id} color='#537577'>
						COPY ID
						<img src={eyeIcon} alt='info' className='c-press-conference-table__copy-id--icon'/>
					</Tooltip>
				</button>
				<button onClick={() => showEditEventModal(event)} className={`c-press-conference-table__edit-button`}>
					<p>Edit Event</p>
				</button>
			</div>
		</section>
	}

	return (
		<>
			{loading ?
			<>
			<section className="press-container">
				{isXL || isLg ?
					<>
						<Cell columns={3}>
							{isAdminOrPressOffice ?
								<button className="c-press-conference-table__add-event" onClick={showModal}>
									<PlusOutlined className="add-event-icon" />
									{props.data.otherComponents[0].formName}
								</button> :
								null
							}
						</Cell>
						<h2 className={'c-press-conference-table__table-title'}>Upcoming Press Conferences</h2>
						<TableComponent tableDataSource={upcomingEventDataSource} columns={columns} tableFirstPosition={tableFirstPosition} tableSecondPosition={tableSecondPosition} pageSize={pageSize} tableLayout={tableLayout} itemRender={itemRender} className={'c-press-conference-table'}/>
						<h2 className={'c-press-conference-table__table-title'}>Past Press Conferences</h2>
						<TableComponent tableDataSource={pastEventDataSource} columns={columns} tableFirstPosition={tableFirstPosition} tableSecondPosition={tableSecondPosition} pageSize={pageSize} tableLayout={tableLayout} itemRender={itemRender} className={'c-press-conference-table'}/>

					</> :
					<>
						<div className='c-press-conference-table__list-title'>
							<h2>Upcoming Press Conferences</h2>
						</div>
						{upcomingEventsListData.map((event) => renderListItem(event))}
						<div className='c-press-conference-table__list-title'>
							<h2>Past Press Conferences</h2>
						</div>
						{pastEventsListData.map((event) => renderListItem(event))}
						<div className="c-press-conference-table__add-event-container">
							{isAdminOrPressOffice ?
								<button className="c-press-conference-table__add-event" onClick={showModal}>
									<PlusOutlined className="add-event-icon" />
									{props.data.otherComponents[0].formName}
								</button> :
								null
							}
						</div>
					</>
				}
				<div className="add-event-form">
					<Modal
						className="event-modalpopup create-event"
						title={props.data.otherComponents[0].formName}
						open={isModalOpen}
						onOk={handleOk}
						onCancel={handleCancel}
						footer={null}
					>
						<FormGroup data={props.data.otherComponents[0]} closeEditPopUp={closeEditPopUp} />
					</Modal>
				</div>
			</section>
			<Modal
				className="event-modalpopup edit-event"
				title={props.data.otherComponents[1].formName}
				open={isEditEventModalOpen}
				footer={null}
				onOk={handleOk}
				onCancel={handleCancel}
				closeEditPopUp={closeEditPopUp}
			>
				<FormGroup closeEditPopUp={closeEditPopUp} data={props.data.otherComponents[1]} preFillValues={editEventPrefillValues} />
			</Modal>
			</>
				: <ScreenLoadingOverlay active={true} />}

	</>
	);
}
